// 隐私号--绑定关系管理
import { Request } from '@/http/request'

// 绑定关系列表
export function getBindList(parameter: any) {
  return Request.axiosInstance.post('/open/bind/list', parameter)
}

// 绑定关系列表ES
export function getEsBindList(parameter: any) {
  return Request.axiosInstance.post('/open/es-bind/list', parameter)
}
export function recycleList(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-recycle/list', parameter)
}
//批量审核
export function batchReview(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-recycle/batch-review', parameter)
}
//取消申请 

export function cancelReview(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-recycle/cancel-review', parameter)
}
//d导出 
export function recycleExport(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-recycle/export', parameter)
}
export function numberRecover(parameter: any) {
  return Request.axiosInstance.post('/open/user-number/number-recover-audit', parameter)
}
//批量取消退网
export function writeCancel(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-recycle/write-cancel', parameter)
}
export default { getBindList, getEsBindList,recycleList,batchReview,cancelReview,recycleExport,numberRecover,writeCancel}